<template>
  <div
    class="l-forms min-h-dvh grid"
    :class="{
      'grid-rows-[245px_1fr_70px_70px] lg:grid-rows-[110px_1fr_70px]': !checkinStarted,
      'grid-rows-[110px_32px_1fr]': checkinStarted && guests.length > 1 && !isPaymentPage,
      'grid-rows-[110px_1fr_70px_70px] lg:grid-rows-[110px_1fr_70px]': checkinStarted && guests.length === 1 && !isPaymentPage,
      'grid_rows-[110px_1fr_110px]': checkinStarted && isPaymentPage,
      '': checkinStarted && !isPaymentPage
    }"
  >
    <FormsHeader />
    <slot />
    <div
      class="w-full flex flex-col justify-center"
      :class="{
        'pb-6': checkinStarted && guests.length > 1
      }"
    >
      <div v-if="isPaymentPage" class="flex space-x-4 justify-center mt-6">
        <img src="/images/secure-ssl.svg" />
        <img src="/images/safe-checkout.svg" />
      </div>
      <img src="/images/powered-by.svg" class="max-h-11 h-full mx-auto" width="145px">
    </div>
  </div>
</template>

<script lang="ts" setup>
const { guests } = useWebsiteStore()
import { useLocalStorage } from '@vueuse/core'
const checkinStarted = useLocalStorage('shackle-startedCheckin', false, { initOnMounted: true })

const route = useRoute()

const isPaymentPage = ref(false)
watch(() => route.name, () => {
  isPaymentPage.value = route.name === 'payment'
}, { immediate: true })

provide('isPaymentPage', isPaymentPage)
</script>

<style>
.l-forms {
  background-image: linear-gradient(#fff, #f7f7fa);
}
</style>
